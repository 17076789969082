// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-24-hour-plumber-jsx": () => import("./../../../src/pages/24-hour-plumber.jsx" /* webpackChunkName: "component---src-pages-24-hour-plumber-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-after-hour-plumber-jsx": () => import("./../../../src/pages/after-hour-plumber.jsx" /* webpackChunkName: "component---src-pages-after-hour-plumber-jsx" */),
  "component---src-pages-backwater-valve-plumbers-toronto-jsx": () => import("./../../../src/pages/backwater-valve-plumbers-toronto.jsx" /* webpackChunkName: "component---src-pages-backwater-valve-plumbers-toronto-jsx" */),
  "component---src-pages-commercial-construction-jsx": () => import("./../../../src/pages/commercial-construction.jsx" /* webpackChunkName: "component---src-pages-commercial-construction-jsx" */),
  "component---src-pages-commercial-plumbing-troubleshooting-jsx": () => import("./../../../src/pages/commercial-plumbing-troubleshooting.jsx" /* webpackChunkName: "component---src-pages-commercial-plumbing-troubleshooting-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-drain-cleaning-inspection-commercial-jsx": () => import("./../../../src/pages/drain-cleaning-inspection-commercial.jsx" /* webpackChunkName: "component---src-pages-drain-cleaning-inspection-commercial-jsx" */),
  "component---src-pages-emergency-plumber-jsx": () => import("./../../../src/pages/emergency-plumber.jsx" /* webpackChunkName: "component---src-pages-emergency-plumber-jsx" */),
  "component---src-pages-fixture-upgrades-commercial-jsx": () => import("./../../../src/pages/fixture-upgrades-commercial.jsx" /* webpackChunkName: "component---src-pages-fixture-upgrades-commercial-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kitec-removal-commercial-jsx": () => import("./../../../src/pages/kitec-removal-commercial.jsx" /* webpackChunkName: "component---src-pages-kitec-removal-commercial-jsx" */),
  "component---src-pages-kitec-removal-jsx": () => import("./../../../src/pages/kitec-removal.jsx" /* webpackChunkName: "component---src-pages-kitec-removal-jsx" */),
  "component---src-pages-leak-detection-phyn-jsx": () => import("./../../../src/pages/leak-detection-phyn.jsx" /* webpackChunkName: "component---src-pages-leak-detection-phyn-jsx" */),
  "component---src-pages-licensed-plumber-jsx": () => import("./../../../src/pages/licensed-plumber.jsx" /* webpackChunkName: "component---src-pages-licensed-plumber-jsx" */),
  "component---src-pages-plumber-brampton-jsx": () => import("./../../../src/pages/plumber-brampton.jsx" /* webpackChunkName: "component---src-pages-plumber-brampton-jsx" */),
  "component---src-pages-plumber-mississauga-jsx": () => import("./../../../src/pages/plumber-mississauga.jsx" /* webpackChunkName: "component---src-pages-plumber-mississauga-jsx" */),
  "component---src-pages-plumber-oakville-jsx": () => import("./../../../src/pages/plumber-oakville.jsx" /* webpackChunkName: "component---src-pages-plumber-oakville-jsx" */),
  "component---src-pages-plumbing-services-jsx": () => import("./../../../src/pages/plumbing-services.jsx" /* webpackChunkName: "component---src-pages-plumbing-services-jsx" */),
  "component---src-pages-riser-replacement-commercial-jsx": () => import("./../../../src/pages/riser-replacement-commercial.jsx" /* webpackChunkName: "component---src-pages-riser-replacement-commercial-jsx" */),
  "component---src-pages-watermain-replacement-commercial-jsx": () => import("./../../../src/pages/watermain-replacement-commercial.jsx" /* webpackChunkName: "component---src-pages-watermain-replacement-commercial-jsx" */),
  "component---src-pages-watermain-replacement-jsx": () => import("./../../../src/pages/watermain-replacement.jsx" /* webpackChunkName: "component---src-pages-watermain-replacement-jsx" */)
}

